import './index.css';
import './assets/fonts/fonts.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import reportWebVitals from './reportWebVitals';
import TagManager from "react-gtm-module"
import { datadogRum } from '@datadog/browser-rum';
import config from './config/index';
import { DebugButton } from './components/utils/DumpState';
import { LoadingLayout } from './layouts/LoadingLayout';
import { FlagProvider } from '@unleash/proxy-client-react';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { RecoilRoot } from 'recoil';
import { GameSheetTheme } from '@gamesheet/ui';
import { GameSheetRouter } from './Router';
import { ErrorMessage } from './components/pure/ErrorMessage';
import { ErrorBoundary } from 'react-error-boundary';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

TagManager.initialize({ gtmId: 'GTM-PDKWKG8' })

if (!process.env.REACT_APP_LOCAL) {
  datadogRum.init({
    applicationId: (process.env.REACT_APP_DD_applicationId || "edaa4927-588f-4992-8892-c1d81e23245b"),
    clientToken: (process.env.REACT_APP_DD_clientToken || "pubabd2d8762a6688326f9392a9966f2841"),
    site: 'datadoghq.com',
    service: (process.env.REACT_APP_DD_service || "team-dashboard"),
    env: (process.env.REACT_APP_CF_PAGES_URL || "unknown"),
    version: (process.env.REACT_APP_CF_PAGES_COMMIT_SHA || "unknown"), 
    sessionSampleRate: Number(process.env.REACT_APP_DD_sessionSampleRate || 40),
    sessionReplaySampleRate: Number(process.env.REACT_APP_DD_sessionReplaySampleRate || 20),
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow'
  });
    
  datadogRum.startSessionReplayRecording();
}

const featureFlagConfig = {
  ...config.unleash,
  url: config.services.unleashApi,
  refreshInterval: 30,
  appName: 'team-dashboard',
  sessionId: '',
  remoteAddress: '',
  currentTime: '',
  userId: '',
  properties: {
      sportId: ''
  }
};

root.render(
    <React.StrictMode>
      <FlagProvider config={featureFlagConfig}>
        <GameSheetTheme theme={{
          primaryColor: '#FEC307',
          secondaryColor: 'rgb(54, 56, 61)',
          textOnPrimaryColor: 'rgb(54, 56, 61)',
          textOnSecondaryColor: '#ffffff',
          success: '#28a745',
          info: '#274be7',
          danger: "#dc3545"
        }}>
          <RecoilRoot>
            <ErrorBoundary fallback={<ErrorMessage message="There was an error."/>}>
              <Suspense fallback={<LoadingLayout />}>
                <GameSheetRouter />
                { config.settings.showDumpStateButton && <DebugButton /> }
              </Suspense>
            </ErrorBoundary>
          </RecoilRoot>
        </GameSheetTheme>
      </FlagProvider>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
