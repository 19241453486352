import { atom } from "recoil";
import { AcceptInvitationFlowStep as Step, AcceptInvitationFlowType } from "@/flows/AcceptInvitationFlow/types";

export const AcceptInvitationFlowState = atom<AcceptInvitationFlowType>({
    key: 'AcceptInvitationFlowState',
    default: {
        loading: false,
        invitationCode: "",
        invitation: undefined,
        rawMergedData: undefined,
        prototeamOptions: {
            strategy: "default",
            linkedTeam: undefined,
        },
        error: "",
        step: Step.InsertInvitationCode,
        paths: {
            newUserPath: [
                Step.InsertInvitationCode,
                Step.PreviewTeam,
                Step.RegisterNewAccount,
            ],
            existingUserPath: [
                Step.InsertInvitationCode,
                Step.PreviewTeam,
                Step.PrototeamOptions,
                Step.SelectedOptionSummary,
                Step.Success,
                Step.SchedulerPreview,
            ]
        }
    }
})