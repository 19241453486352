import styled from "styled-components";
import { Game, Team as TeamDto, Home, Visitor } from "@/state/data/types";
import homeJerseryPlaceholder from "@/assets/images/home_jersey.svg";
import visitorJerseryPlaceholder from "@/assets/images/visitor_jersey.svg";
import { useSeasonsData } from "@/state/data/useSeasonTeams";
import { useAppState } from "@/state/app/useAppState";
import { EventToFinalScore } from "@/libs/eventToWinLossScore";
import dayjs from "@/libs/dayjs";
import { MouseEvent } from "react";
import { Event } from "@/state/data/types";
import { LineupTeam, useLatestLineup } from "@/flows/LineupFlow/state/useLatestLineup";
import { useFlag } from "@unleash/proxy-client-react";

const StyledFeatureGameCard = styled.div.attrs({
  className: "game-card",
})`
  margin: 1px;
  border-radius: inherit;
  border: 1px solid transparent;
  position: relative;

  &:hover {
    cursor: pointer;
  }

`;

const Team = styled.div`
  width: 100%;
  display: flex;
  color: #000;
  background-color: #ffffff;
  align-items: center;
`;

const Logo = styled.img.attrs({
  className: "team-logo",
})`
  grid-area: logo;
  width: 68px;
  height: 100%;
  border-radius: 0 5px 5px 0;
  object-fit: contain;
`;

const GameTime = styled.div.attrs({
  className: "time",
})`
  grid-area: time;
  place-self: right flex-start;
  flex: 100%;
  padding: 8px;
  font-size: 1.1rem;
  font-weight: 500;
  flex-basis: 100%;
  text-transform: uppercase;
  font-family: "Rubik";
  white-space: nowrap;
`;

const GameSchedTime = styled.div.attrs({
  className: "time",
})`
  grid-area: time;
  place-self: center;
  flex: 100%;
  padding: 6px;
  line-height: 2;
  font-size: 1.1rem;
  font-weight: 500;
  flex-basis: 100%;
  text-transform: uppercase;
  font-family: "Rubik";
  white-space: nowrap;
`;

const EventTime = styled.div.attrs({
  className: "time",
})`
  grid-area: time;
  place-self: left flex-start;
  flex: 100%;
  padding: 8px;
  font-size: 1.1rem;
  font-weight: 500;
  flex-basis: 100%;
  text-transform: uppercase;
  font-family: "Rubik";
  white-space: nowrap;
`;

const EventFinalTime = styled.div.attrs({
  className: "time",
})`
  grid-area: time;
  place-self: center flex-start;
  text-align: right;
  font-size: 0.9rem;
  text-transform: uppercase;
  font-family: "Rubik";
  white-space: nowrap;
`;

const Title = styled.div.attrs({
  className: "title",
})`
  grid-area: title;
  place-self: center flex-start;
  flex: 100%;
  flex-direction: column;
  padding: 8px 6px 0px 6px;
  margin-bottom: 6px;
  font-size: 0.9rem;
  flex-basis: 100%;
  text-transform: uppercase;
  font-family: "Rubik";
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

const EventFinalTitle = styled.div.attrs({
  className: "title",
})`
  grid-area: title;
  place-self: center flex-start;
  flex-direction: column;
  padding: 6px 6px 6px 16px;
  font-size: 1rem;
  font-weight: 500;
  flex-basis: 100%;
  text-transform: uppercase;
  font-family: "Rubik";
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

const Location = styled.div.attrs({
  className: "location",
})`
  grid-area: location;
  font-weight: 300;
  font-size: 0.8rem;
  padding: 0 8px;
  margin-left: 10px;
`;

const EventFinalLocation = styled.div.attrs({
  className: "location",
})`
  grid-area: location;
  font-size: 0.9rem;
  text-align: left;
  display: flex;
  place-self: center flex-start;
`;

const Score = styled.div`
  padding: 4px;
  text-align: left;
  font-weight: 500;
  font-size: 1.3rem;
  font-family: "Kapra Neue Pro";
  place-self: center center;
  grid-area: score;
`;

const StyledDate = styled.div.attrs({
  className: "date",
})<{ $eventType?: string }>`
  grid-area: date;
  font-family: "Rubik";
  place-self: stretch stretch;
  border-radius: 5px 0px 0px 5px;
  text-transform: uppercase;

  color: ${(props) => {
    switch (props.$eventType) {
      case "game":
        return "#FFF";
      default:
        return "#000";
    }
  }};
  font-weight: 400;
  line-height: 1;
  padding-top: 8px;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.4;

  div {
    display: block;
    margin-top: -6px;
  }

  span {
    color: ${(props) => {
      switch (props.$eventType) {
        case "game":
          return "#CCC";
        default:
          return "#000";
      }
    }};
  }
`;

const Versus = styled.div.attrs({
  className: "versus",
})<{ $isHome?: boolean }>`
  font-size: 2rem;
  font-weight: 500;
  font-family: "Rubik";
  text-align: center;

  grid-area: vs;
`;

const EventVersus = styled.div.attrs({
  className: "versus",
})`
  font-size: 0.9rem;
  font-weight: 500;
  font-family: "Rubik";
  text-align: left;
  display: flex;
  place-self: center flex-start;
  padding-left: 3px;
  padding-right: 3px;

  grid-area: vs;
`;

const StyledListScheduledGameCard = styled.div.attrs({
  className: "game-card-list-scheduled",
})<{ $eventType: string }>`
  display: grid;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 3px 5px 0px rgb(0 0 0 / 10%);
  background-color: #fff;

  grid-template-columns: 75px 1fr 0.5fr 5fr;
  grid-template-areas:
    "date time vs title"
    "date location location location";

  .date {
    background-color: ${(props) => {
      switch (props.$eventType) {
        case "game":
          return "#36383D";
        case "practice":
          return "#FEDB6A";
        default:
          return "#D7D7D8";
      }
    }};
  }

  .title {
    font-size: 18px;
    line-height: 21.33px;
  }

  .time {
    font-size: 24px;
    font-weight: 500;
    // font-family: 'Kapra Neue Pro';
    text-align: left;
    line-height: 28.44px;
    margin-left: 10px;
    align-self: center;
  }

  .versus {
    font-size: 18px;
    font-weight: 300;
    align-self: center;
    font-style: none;
    padding: 0px 0px 2px 0px;
  }

  .location {
    font-size: 12px;
    padding-left: 10px;
  }
`;

const StyledListScheduledEventCard = styled.div.attrs({
  className: "game-card-list-scheduled",
})<{ $eventType: string }>`
  display: grid;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 3px 5px 0px rgb(0 0 0 / 10%);
  background-color: #fff;

  grid-template-columns: 75px 5fr 20px 1.5fr;
  grid-template-areas:
    "date title vs time"
    "date location location location";

  .date {
    background-color: ${(props) => {
      switch (props.$eventType) {
        case "game":
          return "#36383D";
        case "practice":
          return "#FEDB6A";
        default:
          return "#D7D7D8";
      }
    }};
  }

  .title {
    margin-left: 10px;
    font-size: 18px;
    line-height: 21.33px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .time {
    font-size: 24px;
    font-weight: 500;
    // font-family: 'Kapra Neue Pro';
    text-align: right;
    line-height: 28.44px;
  }

  .location {
    font-size: 12px;
    text-align: right;
  }
`;

const StyledListFinalGameCard = styled.div.attrs({
  className: "game-card-list-final",
})`
  display: grid;
  cursor: pointer;
  box-shadow: 0 3px 5px 0px rgb(0 0 0 / 10%);
  grid-template-columns: 100px 35px auto 45px;
  grid-template-areas: "score logo title date";
  background-color: #fff;
  line-height: 1;

  .team-logo {
    width: 35px;
  }

  .date {
    color: #38393e;
  }
`;

const StyledListFinalScheduledGameCard = styled.div.attrs({
  className: "game-card-list-final",
})`
  display: grid;
  cursor: pointer;
  box-shadow: 0 3px 5px 0px rgb(0 0 0 / 10%);
  grid-template-columns: 100px 35px auto 45px;
  grid-template-areas: "time logo title date";
  background-color: #fff;
  line-height: 1;

  .team-logo {
    width: 35px;
  }

  .date {
    color: #38393e;
  }
`;

const StyledListFinalEventCard = styled.div.attrs({
  className: "game-card-list-final",
})`
  display: grid;
  cursor: pointer;
  box-shadow: 0 3px 5px 0px rgb(0 0 0 / 10%);
  grid-template-columns: auto auto auto 3fr 45px;
  grid-template-areas: "title time vs location date";
  background-color: #fff;
  line-height: 1;

  .team-logo {
    width: 35px;
  }

  .date {
    color: #38393e;
  }
`;

const FeaturedGame = styled.div<{$isRemoteLineupsEnabled: boolean}>`
  display: grid;
  background-color: #fff;
  border-radius: 0 0 5px 5px;
  border: 2px solid ${(p) => p.theme.primaryColor};
  margin-bottom: 10px;
  padding-bottom: ${(p) => (p.$isRemoteLineupsEnabled ? "24px" : "8px")};

  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto auto auto;
  grid-template-areas:
    "location location location"
    "visitor . home"
    "visitor time home"
    "visitor date home"
    "visitor . home"
    "visitor . home";

  .title {
    line-height: 0.9;
    font-weight: 500;
    font-family: "Rubik";
  }

  .location {
    text-align: center;
  }

  .date {
    color: #38393e;
    place-self: start center;
  }

  .time {
    place-self: end center;
    font-family: "Kapra Neue Pro";
    font-size: 1.8rem;
    font-weight: 500;
    padding-bottom: 0;
  }

  > .featured-team {
    place-self: center center;
    text-align: right;

    .team-logo {
      place-self: end;
    }
  }

  > .featured-team ~ .featured-team {
    align-self: start;
    text-align: left;

    .team-logo {
      place-self: start;
    }
  }

  span {
    color: #86888b;
  }
`;

const GameCardHeader = styled.div`
  background-color: ${(p) => p.theme.primaryColor};
  border-radius: 5px 5px 0 0;
  font-weight: 500;
  font-family: "Rubik";
  font-size: 1.2rem;
  text-transform: uppercase;
  line-height: 1;
  padding: 8px;
`;

const FeaturedTeam = styled.div.attrs({
  className: "featured-team",
})`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 75px 1fr;
  grid-template-areas:
    "logo"
    "title";

  &::after {
    display: none;
  }
`;

const FeatureHomeTeam = styled(FeaturedTeam)`
  grid-area: home;
`;
const FeatureVisitorTeam = styled(FeaturedTeam)`
  grid-area: visitor;
`;

const BorderText = styled.div`
  position: absolute;
  bottom: -12%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  color: #000;
  z-index: 1;
  border-radius: 5px;
  padding: 6px;
  background: #fec307;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;

  &.already-set {
    background-color: #36383d;
    color: #fff;
  }
`;

type GameCardProps = {
  game: any;
  teamId: string;
  hideText?: boolean;
  template?: "featured" | "live" | "list-schedule" | "list-final";
  isRemoteLineupsEnabled?: boolean;
  latestLineup?: LineupTeam | null;
};

export function GameCard({ game, template, isRemoteLineupsEnabled, latestLineup }: GameCardProps): JSX.Element {
  const app = useAppState();
  const { teams, teamIds } = useSeasonsData();
  const toBeDetermined = { id: 0, title: "TBD", logo: "" };
  const navToBoxScore =
    "season" in game ? app.navigateOnClick(`/games/${game.season?.id}/${game.id}`) : game.type === "practice" ? app.navigateOnClick(`/practice/${game.id}`) : app.navigateOnClick(`/event/${game.id}`);
  const team = (teams.find((v) => Number(v.id) == game.home?.id) ? game?.visitor : game?.home) || toBeDetermined;

  const gameProps = {
    date: game?.type === "game" ? dayjs.utc(game?.scheduledStartTime) : dayjs(game?.scheduledStartTime),
    onClick: navToBoxScore,
    team,
    isHome: team.id != game.home?.id,
    location: game?.location,
    type: game?.type,
    eventTitle: game?.title,
    gameStatus: game?.status,
    latestLineup,
    isRemoteLineupsEnabled,

  };

  switch (template) {
    case "list-schedule": {
      return <ListScheduleGameCard {...gameProps} />;
    }

    case "list-final": {
      const { result, homeTeamScore, visitorTeamScore } = EventToFinalScore(game, teamIds);
      const props = { ...gameProps, result, homeScore: homeTeamScore, visitorScore: visitorTeamScore };
      return <ListFinalGameCard {...props} />;
    }

    case "featured": {
      const props = { ...gameProps, home: game?.home, visitor: game?.visitor, game, latestLineup, isRemoteLineupsEnabled };
      return <FeatureGameCard {...props} />;
    }
  }

  return <></>;
}

type GameCardTemplateProps = {
  date: dayjs.Dayjs;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
};

type TeamScopedTemplateProps = GameCardTemplateProps & {
  team: TeamDto;
  isHome: boolean;
};

type ScheduleGameTemplateProps = TeamScopedTemplateProps & {
  location: string;
  type: string;
  eventTitle: string | undefined;
};

type FinalGameTemplateProps = TeamScopedTemplateProps & {
  result: string;
  visitorScore: number;
  homeScore: number;
  type: string;
  eventTitle: string | undefined;
  location: string;
  isHome: boolean;
  gameStatus: string;
};

type FeatureGameTemplateProps = ScheduleGameTemplateProps & {
  home: Home;
  visitor: Visitor;
  game: Game;
  isRemoteLineupsEnabled?: boolean;
  latestLineup?: LineupTeam | null;
};

export function ListScheduleGameCard({ team, isHome, date, location, type, eventTitle, onClick }: ScheduleGameTemplateProps) {
  const time = date.format("h:mm A");
  const month = date.format("MMM").toUpperCase();
  const day = date.format("D");
  const dow = date.format("ddd").toUpperCase();

  return (
    <>
      {" "}
      {type === "game" && (
        <StyledListScheduledGameCard onClick={onClick} $eventType={type}>
          <StyledDate $eventType={type}>
            <div>
              {dow}
              <br />
              <span>
                {month} {day}
              </span>
            </div>
          </StyledDate>
          <GameTime>{time}</GameTime>
          <Versus $isHome={isHome}>{isHome ? "vs" : "@"}</Versus>
          <Title>{team.title}</Title>
          <Location>{location}</Location>
        </StyledListScheduledGameCard>
      )}
      {type !== "game" && (
        <StyledListScheduledEventCard onClick={onClick} $eventType={type}>
          <StyledDate $eventType={type}>
            <div>
              {dow}
              <br />
              <span>
                {month} {day}
              </span>
            </div>
          </StyledDate>
          <Title>{eventTitle}</Title>
          <EventTime>{time}</EventTime>
          <Location>{location}</Location>
        </StyledListScheduledEventCard>
      )}
    </>
  );
}

export function ListFinalGameCard({ team, result, visitorScore, homeScore, date, type, eventTitle, location, isHome, gameStatus, onClick }: FinalGameTemplateProps) {
  const time = date.format("h:mm A");
  const month = date.format("MMM").toUpperCase();
  const day = date.format("D");
  const dow = date.format("ddd").toUpperCase();

  return (
    <>
      {" "}
      {type === "game" && gameStatus !== "scheduled" && (
        <StyledListFinalGameCard onClick={onClick}>
          <Score>
            {result} {visitorScore} - {homeScore}
          </Score>
          <Logo src={team.logo ? `${team.logo}/256` : homeJerseryPlaceholder} />
          <Title>{team.title}</Title>
          <StyledDate>
            <div>
              {month}
              <br />
              {day}
            </div>
          </StyledDate>
        </StyledListFinalGameCard>
      )}
      {type === "game" && gameStatus === "scheduled" && (
        <StyledListFinalScheduledGameCard onClick={onClick}>
          <GameSchedTime>{time}</GameSchedTime>
          <Logo src={team.logo ? `${team.logo}/256` : homeJerseryPlaceholder} />
          <Title>{team.title}</Title>
          <StyledDate>
            <div>
              {month}
              <br />
              {day}
            </div>
          </StyledDate>
        </StyledListFinalScheduledGameCard>
      )}
      {type !== "game" && (
        <StyledListFinalEventCard onClick={onClick}>
          <EventFinalTitle>{eventTitle}</EventFinalTitle>
          <EventFinalTime>{time}</EventFinalTime>
          <EventVersus>{location != "" ? "@" : ""}</EventVersus>
          <EventFinalLocation>{location}</EventFinalLocation>
          <StyledDate>
            <div>
              {month}
              <br />
              {day}
            </div>
          </StyledDate>
        </StyledListFinalEventCard>
      )}
    </>
  );
}

export function FeatureGameCard({ home, visitor, date, location, onClick, game, isRemoteLineupsEnabled, latestLineup }: FeatureGameTemplateProps) {
  const app = useAppState();
  const time = date.format("h:mm A");
  const month = date.format("MMM").toUpperCase();
  const day = date.format("D");
  const dow = date.format("dddd").toUpperCase();
  // const { latestLineup, loading } = useLatestLineup(game.id, app.selectedTeam);
  // const isRemoteLineupsEnabled = useFlag('RemoteLineups');


  const HandleBorderTextClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    app.navigate(`/teams/${app.selectedTeam}/games/${game.id}/lineups`);
  };
  

  return (
    <StyledFeatureGameCard onClick={onClick} >
      <GameCardHeader>Next Game</GameCardHeader>

      <FeaturedGame $isRemoteLineupsEnabled={isRemoteLineupsEnabled ? true : false}>
        <Location>{location}</Location>
        <GameTime>{time}</GameTime>
        <StyledDate>
          <div>
            <span>{dow}</span>
            <br />
            <span>
              {month} {day}
            </span>
          </div>
        </StyledDate>
        <FeatureVisitorTeam>
          <Logo src={visitor.logo ? `${visitor.logo}/256` : visitorJerseryPlaceholder} />
          <Title>{visitor.title}</Title>
        </FeatureVisitorTeam>
        <FeatureHomeTeam>
          <Logo src={home.logo ? `${home.logo}/256` : homeJerseryPlaceholder} />
          <Title>{home.title}</Title>
        </FeatureHomeTeam>

        {isRemoteLineupsEnabled && (
          <BorderText className={latestLineup ? "already-set" : ""} onClick={HandleBorderTextClick}>
            {latestLineup?.players ? (
              <>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.8353 0.468352C15.1369 0.169417 15.544 0.00117769 15.9686 6.15943e-06C16.3932 -0.00116537 16.8013 0.164825 17.1045 0.462091C17.4077 0.759357 17.5817 1.16401 17.5889 1.58858C17.5962 2.01314 17.436 2.42349 17.1431 2.73092L8.54109 13.4835C8.39325 13.6427 8.21481 13.7705 8.01646 13.8592C7.8181 13.9479 7.60389 13.9957 7.38664 13.9997C7.16939 14.0037 6.95357 13.9639 6.75206 13.8826C6.55056 13.8013 6.36751 13.6802 6.21388 13.5266L0.514374 7.82491C0.355592 7.67696 0.228238 7.49854 0.139907 7.3003C0.0515768 7.10206 0.00408016 6.88805 0.00025151 6.67106C-0.00357714 6.45406 0.0363407 6.23851 0.117623 6.03728C0.198905 5.83604 0.319886 5.65324 0.47335 5.49978C0.626814 5.34632 0.809616 5.22533 1.01085 5.14405C1.21209 5.06277 1.42763 5.02285 1.64463 5.02668C1.86162 5.03051 2.07563 5.07801 2.27387 5.16634C2.47211 5.25467 2.65053 5.38202 2.79849 5.5408L7.31068 10.0508L14.7944 0.515758C14.8077 0.499066 14.8221 0.483232 14.8375 0.468352H14.8353Z"
                    fill="#F5F5F4"
                  />
                </svg>
                Lineup set
              </>
            ) : (
              <>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.3333 20C10.3333 20 9 20 9 18.6667C9 17.3333 10.3333 13.3333 15.6667 13.3333C21 13.3333 22.3333 17.3333 22.3333 18.6667C22.3333 20 21 20 21 20H10.3333ZM15.6667 12C16.7275 12 17.7449 11.5786 18.4951 10.8284C19.2452 10.0783 19.6667 9.06087 19.6667 8C19.6667 6.93913 19.2452 5.92172 18.4951 5.17157C17.7449 4.42143 16.7275 4 15.6667 4C14.6058 4 13.5884 4.42143 12.8382 5.17157C12.0881 5.92172 11.6667 6.93913 11.6667 8C11.6667 9.06087 12.0881 10.0783 12.8382 10.8284C13.5884 11.5786 14.6058 12 15.6667 12ZM7.95467 20C7.75701 19.5838 7.65843 19.1274 7.66667 18.6667C7.66667 16.86 8.57333 15 10.248 13.7067C9.41212 13.4491 8.54127 13.3232 7.66667 13.3333C2.33333 13.3333 1 17.3333 1 18.6667C1 20 2.33333 20 2.33333 20H7.95467ZM7 12C7.88406 12 8.7319 11.6488 9.35702 11.0237C9.98214 10.3986 10.3333 9.55072 10.3333 8.66667C10.3333 7.78261 9.98214 6.93477 9.35702 6.30964C8.7319 5.68452 7.88406 5.33333 7 5.33333C6.11595 5.33333 5.2681 5.68452 4.64298 6.30964C4.01786 6.93477 3.66667 7.78261 3.66667 8.66667C3.66667 9.55072 4.01786 10.3986 4.64298 11.0237C5.2681 11.6488 6.11595 12 7 12Z"
                    fill="#36383D"
                  />
                </svg>
                Set Lineup
              </>
            )}
          </BorderText>
        )}
      </FeaturedGame>
    </StyledFeatureGameCard>
  );
}

type GameCardTeamProps = {
  logo: string;
  title: string;
  subtitle: string;
  score: number | string;
  showScore?: boolean;
  hideText?: boolean;
};

export function GameCardTeam(props: GameCardTeamProps) {
  return (
    <Team>
      <Logo src={props.logo} />
      <Title>
        {props.hideText || (
          <>
            <div>{props.title}</div>
            <div className="subtitle">{props.subtitle}</div>
          </>
        )}
      </Title>
      {props.showScore && <Score>{props.score}</Score>}
    </Team>
  );
}
