import { atom, useRecoilState } from "recoil";
import { ExtendedCoachFormData, ExtendedPlayerFormData, LineupRecord } from "../types";
import { useCallback } from "react";
import { useAppState } from "@/state/app/useAppState";
import { useUserState } from "@/state/user/useUserState";
import { useSignatureState } from "./useSignatureState";
import { LineupFlowHook } from "./useLineupFlow";

type LineupDataStateType = {
  localLineupRecord: LineupRecord;
  remoteLineupRecord: LineupRecord;
};

export const LineupDataState = atom<LineupDataStateType>({
  key: "LineupDataState",
  default: {
    localLineupRecord: {
      id: "",
      lineup: {
        players: [],
        goalies: [],
        coaches: [],
      },
      createdAt: 0,
      locked: false,
      gameId: "",
      protoTeamId: "",
      rosterLocked: false,
    },
    remoteLineupRecord: {
      id: "",
      lineup: {
        players: [],
        goalies: [],
        coaches: [],
      },
      createdAt: 0,
      locked: false,
      gameId: "",
      protoTeamId: "",
      rosterLocked: false,
    },
  },
});

export function useLineupData() {
  const app = useAppState();
  const user = useUserState();

  const [state, setState] = useRecoilState(LineupDataState);

  const { svgData } = useSignatureState();

  const submitLineup = useCallback(
    async (flow: LineupFlowHook) => {
      try {
        // Convert IDs to integers
        const players = state.localLineupRecord.lineup.players.map((player) => ({
          ...player,
          id: parseInt(player.id),
        }));
        const goalies = state.localLineupRecord.lineup.goalies.map((goalie) => ({
          ...goalie,
          id: parseInt(goalie.id),
        }));
        const coaches = state.localLineupRecord.lineup.coaches.map((coach) => ({
          ...coach,
          id: parseInt(coach.id),
        }));

        const response = await fetch(`${app.config.gateways.eventLog}/v5/games/${state.localLineupRecord.gameId}/${state.localLineupRecord.protoTeamId}/set-lineup`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.tokens.access}`,
          },
          body: JSON.stringify({
            attributes: {
              event: "set-lineup",
              schema: "lineup",
              version: "v1",
            },
            data: {
              teamId: state.localLineupRecord.protoTeamId,
              gameId: parseInt(state.localLineupRecord.gameId),
              lineupLocked: state.localLineupRecord.locked,
              players: [...players, ...goalies],
              coaches: coaches,
              signature: svgData,
              rosterLocked: state.localLineupRecord.rosterLocked,
              source: "team-app",
            },
          }),
        });

        if (!response.ok) {
          throw new Error("Failed to submit lineup");
        }

        if (response.ok) {
          return 200;
        }
      } catch (error) {
        console.error("Error submitting lineup:", error);
      }
    },
    [state.localLineupRecord, svgData]
  );

  const sortByJerseyNumber = (a: ExtendedPlayerFormData, b: ExtendedPlayerFormData) => {
    if (a.jersey === "") return -1;
    if (b.jersey === "") return 1;

    if (a.jersey === "00") return -1;
    if (b.jersey === "00") return 1;

    const numberA = parseInt(a.jersey, 10);
    const numberB = parseInt(b.jersey, 10);

    if (numberA < numberB) return -1;
    if (numberA > numberB) return 1;
    return 0;
  };

  const sortByFirstName = (a: ExtendedCoachFormData, b: ExtendedCoachFormData) => {
    return a.firstName.localeCompare(b.firstName);
  };

  const setSelectedStatus = useCallback(
    (status: string) => {
      setState((prevState) => {
        const { players, goalies, coaches } = prevState.localLineupRecord.lineup;

        // Find the selected person and update their status
        const updatedPlayers = players.map((player) => ({
          ...player,
          status: player.isSelected ? status : player.status,
          isSelected: false,
        }));
        const updatedGoalies = goalies.map((goalie) => ({
          ...goalie,
          status: goalie.isSelected ? status : goalie.status,
          isSelected: false,
        }));
        const updatedCoaches = coaches.map((coach) => ({
          ...coach,
          status: coach.isSelected ? status : coach.status,
          isSelected: false,
        }));

        return {
          ...prevState,
          localLineupRecord: {
            ...prevState.localLineupRecord,
            lineup: {
              players: updatedPlayers,
              goalies: updatedGoalies,
              coaches: updatedCoaches,
            },
          },
        };
      });
    },
    [setState]
  );

  const setSuspensionById = useCallback(
    (id: string, number: number, length: number) => {
      setState((prevState) => {
        const { players, goalies, coaches } = prevState.localLineupRecord.lineup;

        // Find the person with the provided ID and update their suspension and status
        const updatedPlayers = players.map((player) => ({
          ...player,
          suspension: player.id === id ? { number, length } : player.suspension,
          status: player.id === id ? "suspended" : player.status,
          isSelected: false,
        }));
        const updatedGoalies = goalies.map((goalie) => ({
          ...goalie,
          suspension: goalie.id === id ? { number, length } : goalie.suspension,
          status: goalie.id === id ? "suspended" : goalie.status,
          isSelected: false,
        }));
        const updatedCoaches = coaches.map((coach) => ({
          ...coach,
          suspension: coach.id === id ? { number, length } : coach.suspension,
          status: coach.id === id ? "suspended" : coach.status,
          isSelected: false,
        }));

        return {
          ...prevState,
          localLineupRecord: {
            ...prevState.localLineupRecord,
            lineup: {
              players: updatedPlayers,
              goalies: updatedGoalies,
              coaches: updatedCoaches,
            },
          },
        };
      });
    },
    [setState]
  );

  const setGoalieStartingById = useCallback(
    (id: string) => {
      setState((prevState) => {
        const { goalies } = prevState.localLineupRecord.lineup;

        const updatedGoalies = goalies.map((goalie) => ({
          ...goalie,
          starting: goalie.id === id,
        }));

        return {
          ...prevState,
          localLineupRecord: {
            ...prevState.localLineupRecord,
            lineup: {
              ...prevState.localLineupRecord.lineup,
              goalies: updatedGoalies,
            },
          },
        };
      });
    },
    [setState]
  );

  const setPlayingStatusById = useCallback(
    (id: string) => {
      setState((prevState) => {
        const { players, goalies, coaches } = prevState.localLineupRecord.lineup;

        // Find the person with the provided ID and update their status to "playing"
        const updatedPlayers = players.map((player) => ({
          ...player,
          status: player.id === id ? "playing" : player.status,
        }));
        const updatedGoalies = goalies.map((goalie) => ({
          ...goalie,
          status: goalie.id === id ? "playing" : goalie.status,
        }));
        const updatedCoaches = coaches.map((coach) => ({
          ...coach,
          status: coach.id === id ? "coaching" : coach.status,
        }));

        return {
          ...prevState,
          localLineupRecord: {
            ...prevState.localLineupRecord,
            lineup: {
              players: updatedPlayers,
              goalies: updatedGoalies,
              coaches: updatedCoaches,
            },
          },
        };
      });
    },
    [setState]
  );

  const getSelectedPlayerGoalieOrCoach = useCallback(() => {
    const selectedPlayer = state.localLineupRecord.lineup.players.find((player) => player.isSelected);
    const selectedGoalie = state.localLineupRecord.lineup.goalies.find((goalie) => goalie.isSelected);
    const selectedCoach = state.localLineupRecord.lineup.coaches.find((coach) => coach.isSelected);

    if (selectedPlayer) {
      return { type: "player", data: selectedPlayer };
    } else if (selectedGoalie) {
      return { type: "goalie", data: selectedGoalie };
    } else if (selectedCoach) {
      return { type: "coach", data: selectedCoach };
    } else {
      return null;
    }
  }, [state.localLineupRecord.lineup.players, state.localLineupRecord.lineup.goalies, state.localLineupRecord.lineup.coaches]);

  const selectPlayerGoalieOrCoachById = useCallback(
    (id: string, type: "players" | "goalies" | "coaches") => {
      setState((prevState) => {
        const updatedLineup = { ...prevState.localLineupRecord.lineup };
        const targetArray = updatedLineup[type];

        // Set isSelected to true for the player/goalie/coach with the provided ID
        const updatedArray = targetArray.map((person) => ({
          ...person,
          isSelected: person.id === id,
        }));

        return {
          ...prevState,
          localLineupRecord: {
            ...prevState.localLineupRecord,
            lineup: {
              ...prevState.localLineupRecord.lineup,
              [type]: updatedArray,
            },
          },
        };
      });
    },
    [setState]
  );

  const LocalLineupData = {
    set: (localLineupData: LineupRecord) => {
      // Sort players and goalies by jersey number before updating the state
      const sortedPlayers = [...localLineupData.lineup.players].sort(sortByJerseyNumber);
      const sortedGoalies = [...localLineupData.lineup.goalies].sort(sortByJerseyNumber);

      // Sort coaches by first name
      const sortedCoaches = [...localLineupData.lineup.coaches].sort(sortByFirstName);
      setState((s) => ({
        ...s,
        localLineupRecord: {
          ...s.localLineupRecord,
          gameId: localLineupData.gameId,
          protoTeamId: localLineupData.protoTeamId,
          lineup: {
            players: sortedPlayers,
            goalies: sortedGoalies,
            coaches: sortedCoaches,
          },
          locked: localLineupData.locked,
          rosterLocked: localLineupData.rosterLocked,
          id: localLineupData.id,
        },
      }));
    },
    get: useCallback(() => state.localLineupRecord, [state]),
    deselectAll: useCallback(() => {
      const { players, goalies, coaches } = state.localLineupRecord.lineup;
      const updatedPlayers = players.map((player) => ({ ...player, isSelected: false }));
      const updatedGoalies = goalies.map((goalie) => ({ ...goalie, isSelected: false }));
      const updatedCoaches = coaches.map((coach) => ({ ...coach, isSelected: false }));
      setState((s) => ({
        ...s,
        localLineupRecord: {
          ...s.localLineupRecord,
          lineup: {
            players: updatedPlayers,
            goalies: updatedGoalies,
            coaches: updatedCoaches,
          },
        },
      }));
    }, [setState, state]),

    getSelectedPlayerGoalieOrCoach,
    selectPlayerGoalieOrCoachById,
    setSelectedStatus,
    setPlayingStatusById,
    setSuspensionById,
    setGoalieStartingById,
    submitLineup,
  };

  const RemoteLineupData = {
    set: (remoteLineupData: LineupRecord) => {
      setState((s) => ({ ...s, remoteLineupRecord: remoteLineupData }));
    },
    get: useCallback(() => state.remoteLineupRecord, [state]),
  };

  return {
    LocalLineupData,
    RemoteLineupData,
  };
}
