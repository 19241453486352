import { collection, onSnapshot, query, DocumentReference, Unsubscribe, DocumentData } from "firebase/firestore";
import { useEffect, useState } from "react";
import { atom, selector, useRecoilValue, useSetRecoilState, selectorFamily } from "recoil"
import { useAppState } from "@/state/app/useAppState";
import { Game, Event, Team } from "./types";
import { getOffset, getUserOffsetNow, golangDateTimeIsZero } from "@/libs/timezones";

type TeamEventReference = {
    id: string;
    type: string;
    data: DocumentReference<Game>
} & Partial<Event>

export type TeamEvent = Omit<TeamEventReference, "data"> & Game

type TeamEventsData = {
    data: TeamEvent[],
    lastUpdated: number,
}

type TeamEventStore = Omit<TeamEventsData, "data"> & {
    data: { 
        [key:string]: TeamEvent
    }
}

type EventData = {
    data: { 
        [key:string]: Event
    }
    lastUpdated: number,
}

const eventsDataState = atom<EventData>({
    key: 'eventsDataState',
    default: {
        data: {},
        lastUpdated: 0,
    },
});

const teamEventsDataState = atom<TeamEventStore>({
    key: 'teamEventsDataState',
    default: {
        data: {},
        lastUpdated: 0,
    },
});

const teamGamesDataState = selector<TeamEventsData>({
    key: 'teamGamesDataState',
    get: ({get}) => {
        
        const data = get(teamEventsDataState)
        const events = Object.values(data.data).sort((a,b) => a.normalizedStartDateTime.getTime() - b.normalizedStartDateTime.getTime())

        return {
            ...data,
            data: events.filter((event) => event.type === 'game')
        }
    }
})

const teamLiveGamesDataState = selector<TeamEventsData>({
    key: 'teamLiveGamesDataState',
    get: ({get}) => {
        const data = get(teamGamesDataState)
        return {
            ...data,
            data: data.data.filter((game) => ['inProgress','unofficialFinal'].includes(game.status))
        }
    }
})

const teamScheduledGamesDataState = selector<TeamEventsData>({
    key: 'teamScheduledGamesDataState',
    get: ({get}) => {
        const data = get(teamGamesDataState)
        return {
            ...data,
            data: data.data.filter((game) => ['scheduled','cancelled','postponed'].includes(game.status) )
        }
    }
})

const teamCompletedGameDataState = selector<TeamEventsData>({
    key: 'teamCompletedGameDataState',
    get: ({get}) => {
        const data = get(teamGamesDataState)
        return {
            ...data,
            data: data.data.filter((game) => ['final','completed'].includes(game.status))
        }
    }
})

const nextFutureScheduleGame = selector<TeamEvent>({
    key: 'nextFutureScheduleGame',
    get: ({get}) => {
        const futureGames = get(teamScheduledGamesDataState).data.filter(game => {
            const now = Date.now() - (new Date()).getTimezoneOffset() * 60 * 1000;
            const gameStart = game.ScheduledStartTime.Time.seconds * 1000
            return gameStart > now
        })
        return futureGames[0]
    }
})

export const getOpponent = selectorFamily<Team | undefined, { gameId?: string; teamId?: string | number }>({
    key: 'getOpponent',
    get: ({ gameId, teamId }) => ({ get }) => {
        // this is a selector that returns the opponent of a game based on the gameId and teamId you pass in
        if (!gameId || !teamId) return undefined;
      const teamEventsData = get(teamEventsDataState); 
      const games = Object.values(teamEventsData.data);
      const game = games.find((game) => game.id === parseInt(gameId)); 
  
      if (!game) return undefined;
  
      if (game.home.prototeam.id.toString() === teamId.toString()) return game.visitor;
      if (game.visitor.prototeam.id.toString() === teamId.toString()) return game.home;
  
      return undefined; 
    },
  });

export function useTeamGame(id:string|undefined){
    
    const games = useRecoilValue(teamGamesDataState)
    
    if(!id) return undefined;

    return games.data.find((game) => game.id == id)

}

export function useTeamEventsData(){
    
    const data = useRecoilValue(teamEventsDataState)
    const events = useRecoilValue(eventsDataState)
    const games = useRecoilValue(teamGamesDataState)
    const liveGames = useRecoilValue(teamLiveGamesDataState)
    const scheduledGames = useRecoilValue(teamScheduledGamesDataState)
    const completedGames = useRecoilValue(teamCompletedGameDataState)
    const nextGame = useRecoilValue(nextFutureScheduleGame)

    // pick up changes in data OR events
    const [changeSignal, setChangeSignal] = useState(0);
    const rawChangeSignal = data.lastUpdated > events.lastUpdated ? data.lastUpdated : events.lastUpdated
    if (changeSignal !== rawChangeSignal) {
        setChangeSignal(rawChangeSignal)
    }

    return {
        changeSignal,
        all: () => Object.values(data.data),
        numCompleted: () => completedGames.data.length,
        events: () => Object.values(events.data),
        
        liveGames: (num?:number) =>  !num ? liveGames.data : liveGames.data.slice(0,num),
        scheduledGames: (num?:number) => !num ? scheduledGames.data : scheduledGames.data.slice(0,num),
        completedGames: (num?:number) => !num ? completedGames.data : completedGames.data.slice(0,num),

        nextGame,
        games: games.data
    }
}

export function useReadTeamEventsData(){
    
    const app = useAppState()
    const setTeamEventsData = useSetRecoilState(teamEventsDataState)
    const setEventsData = useSetRecoilState(eventsDataState)
    const subscriptions = new Map<string, Unsubscribe>()

    const addTeamEvent = (key: string, docData: DocumentData) => {
        const unsub = onSnapshot(docData.data, (doc: any) => {
            if(!doc.exists()) {
                removeTeamEvent(key);
                return;
            }

            const innerData = doc.data();

            // raw FS events have start_date_gmt and timezone
            // raw FS scheduled games have scheduledTimeGmt and timeZoneName

            let startDateTime = new Date(innerData?.scheduledStartTime);
            if (!!innerData?.scheduledTimeGmt && !golangDateTimeIsZero(innerData?.scheduledTimeGmt)) {
                startDateTime = new Date(innerData?.scheduledTimeGmt);
            }

            setTeamEventsData((state) => ({
                data: {
                    ...state.data,
                    [key]: {
                        ...innerData,
                        type: docData.type,
                        normalizedStartDateTime: startDateTime, 
                    } as any
                },
                lastUpdated: Date.now(),
            }));
        })

        subscriptions.set(key, unsub)
    }

    const removeTeamEvent = (key: string) => {
        setTeamEventsData((state) => {
            const copy = { ...state, data: { ...state.data }};
            delete copy.data[key];
            copy.lastUpdated = Date.now();
            return copy;
        })

        const unsub = subscriptions.get(key);
        if(unsub) unsub();
    }

    const updateEvent = (docData: DocumentData) => {
        // raw FS events have start_date_gmt and timezone
        // raw FS scheduled games have scheduledTimeGmt and timeZoneName
        
        setEventsData((state: any) => {
            // FS automatically converts timestamps into local time, but we need to interpret them as UTC
            let myTimeZoneOffsetSeconds = getUserOffsetNow() * 60;
            let arbitraryTimeZoneOffsetSeconds = myTimeZoneOffsetSeconds;
            
            let startDateTime = new Date((docData.start_date?.seconds || 0) * 1000);
            if (!!docData.start_date_gmt && docData.start_date_gmt.seconds > 0) {
                startDateTime = new Date((docData.start_date_gmt.seconds || 0) * 1000);
            } else {
                // if they're legacy events, we need to interpret the timezone, then convert into the same time but local
                const eventOffset = getOffset(docData.timezone, startDateTime)
                if (eventOffset !== null) {
                    arbitraryTimeZoneOffsetSeconds = myTimeZoneOffsetSeconds - eventOffset * 60;
                }
            }

            const scheduledStartTime = new Date(((docData.start_date?.seconds || 0) - arbitraryTimeZoneOffsetSeconds) * 1000);

            return {
                data: {
                    ...state.data,
                    [docData.id]: {
                        id: docData.id,
                        eventType: docData.type,
                        type: docData.type,
                        title: docData.title,
                        // start_date: docData.start_date,
                        scheduledStartTime,
                        ScheduledStartTime: {Time: {
                            seconds: scheduledStartTime.getTime() / 1000 + myTimeZoneOffsetSeconds,
                            nanoseconds: 0,
                        }},
                        normalizedStartDateTime: startDateTime,
                        start_date_gmt: docData.start_date_gmt,
                        // end_date: docData.end_date,
                        timezone: docData.timezone,
                        location: docData.location,
                        notes: docData.notes,
                        prototeam_id: docData.prototeam_id,
                        event_group: docData.event_group,
                    }
                },
                lastUpdated: Date.now(),
        }});
    }

    const removeEvent = (docId: string) => {
        setEventsData((state) => {
            const copy = { ...state, data: { ...state.data }};
            delete copy.data[docId];
            copy.lastUpdated = Date.now();
            return copy;
        })
    };

    useEffect(() => {
        if (!app.selectedTeam) return;

        const q = query(collection(app.connections.firestore, "teams", app.selectedTeam, "events"))
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
                const docData = change.doc.data();
                const isTeamEvent = 'data' in docData;

                switch (true) {
                    case (isTeamEvent && change.type === "added"):
                        addTeamEvent(change.doc.id, docData);
                        break;
                    case (isTeamEvent && change.type === "modified"):
                        // do nothing, we already have a listener
                        break;
                    case (isTeamEvent && change.type === "removed"):
                        removeTeamEvent(change.doc.id);
                        break;
                    case (!isTeamEvent && change.type === "added"):
                    case (!isTeamEvent && change.type === "modified"):
                        updateEvent(docData);
                        break;
                    case (!isTeamEvent && change.type === "removed"):
                        removeEvent(change.doc.id);
                        break;
                }
            })
            
            app.loading.complete('events')

        });

        return () => {
            unsubscribe();
            subscriptions.forEach((unsub) => unsub())
            setTeamEventsData({ data: {}, lastUpdated: 0 });
            setEventsData({ data: {}, lastUpdated: 0 });
        }
    }, [ app.selectedTeam ])
}