import { useRecoilValue, useResetRecoilState } from "recoil";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { AcceptInvitationFlowPath, RegistrationData } from "@/flows/AcceptInvitationFlow/types";
import { useInvitationCodeService } from "@/flows/AcceptInvitationFlow/services/useInvitationCodeService";
import { AcceptInvitationFlowState } from "./atom.AcceptInvitationFlowState";
import { AcceptInvitationFlowScreenState } from "./selector.AcceptInvitationFlowScreenState";
import { useManagedFlow } from "@/hooks/useManagedFlow";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { auth } from "@/libs/firebase";
import { SignupFormState } from "@/forms/SignupForm"
import { useAppState } from "@/state/app/useAppState";
import { useResetForm } from "@/forms/hooks/useResetForm";
import { SampleTeamState } from "./selector.SampleTeam";
import { TeamData } from "@/state/data/useTeams";
import { MergePreviewDataState } from "./selector.MergedPreviewWithRoster";
import { LoginFlowPath } from "@/flows/LoginFlow/types";
import { useLoginFlow } from "@/flows/LoginFlow/state/useLoginFlow";
import { useUserState } from "@/state/user/useUserState";

export function useAcceptInvitationFlow(path: AcceptInvitationFlowPath) {

    const app = useAppState();
    const user = useUserState();
    const navigate = useNavigate();
    const reset = useResetRecoilState(AcceptInvitationFlowState)
    const resetSignupForm = useResetForm('signup')
    const { next, prev, to, state, setState } = useManagedFlow(AcceptInvitationFlowState, path)
    const invitationCodeService = useInvitationCodeService();
    const screenState = useRecoilValue(AcceptInvitationFlowScreenState);
    const sampleTeam = useRecoilValue(SampleTeamState);
    const mergedPreview = useRecoilValue(MergePreviewDataState);
    const loginFlow = useLoginFlow(LoginFlowPath.Login)

    // events
    useEffect(() => {
        app.events.add("AcceptInvitationFlow.Complete", reset)
    }, [ reset ])

    return {

        ...state,
        ...screenState,

        get isLoading() { return state.loading || invitationCodeService.isFetching },

        controls: {
            next,
            prev: useCallback(() => {
                setState(state => ({ ...state, error: "" }))
                prev()
            }, [prev, setState]),
            to
        },

        readInvitationCode: useCallback(async (code: string) => {
            //clear any previous errors
            setState(state => ({ ...state, invitation: undefined, error: "" }));

            // get code details
            const response = await invitationCodeService.loadCodeDetails(code)
            const userRoles = response.data?.userAlreadyHasTeamRoles && Object.values(response.data?.userAlreadyHasTeamRoles)
            const allRolesExist = !!userRoles?.length && userRoles?.every((value: boolean) => value);
            const schedulerInvite = response.data?.invitation?.roles?.some((role: any) => role.title === "scheduler")
            const inviteTeamIds = response.data?.teams?.map((team: any) => team.id)
            const hasMatchingManager = app.teams.teamData?.some((prototeam: any) => prototeam.seasonTeams?.some((seasonTeam: any) => inviteTeamIds?.includes(seasonTeam.id)))
            const hasMatchingSeason = app.teams.teamData?.some((prototeam: any) => prototeam.seasonTeams?.some((seasonTeam: any) => response.data?.invitation?.roles?.some((role: any) => role.level.id == seasonTeam.season.id)))

            if (allRolesExist) {
                setState(state => ({ ...state, invitation: undefined, error: "You're already a member of this team" }));
                return
            }

            // save error
            if (response.status === 'error') {
                setState(state => ({ ...state, invitation: undefined, error: response.errors.map((e: any) => e.message).join() }));
                return
            }

            if (!response.data.teams.length && !schedulerInvite) {
                setState(state => ({ ...state, invitation: undefined, error: "No Teams in Invitation" }));
                return
            }

            if (schedulerInvite && state.path === "newUserPath") {
                setState(state => ({ ...state, invitation: undefined, error: "Cannot Register With Scheduler Code" }));
                return
            }

            if (schedulerInvite && (!hasMatchingManager && !hasMatchingSeason) && state.path === "existingUserPath") {
                setState(state => ({ ...state, invitation: undefined, error: "Cannot Accept Role" }));
                return
            }

            // set invitation and continue
            setState(state => ({ ...state, invitation: response.data }));

            // if scheduler invite, set correct path
            if (schedulerInvite) {
                to(5);
            } else {
                next();
            }

        }, [next, invitationCodeService.loadCodeDetails, setState, state.path, app.teams.teamData]),

        confirmCodeCorrect: useCallback(async () => {

            const invite = state.invitation!;
            if (!invite?.teams || !invite?.userAlreadyHasTeamRoles) {
                return;
            }

            const unclaimedTeams = invite.teams.filter(team => {
                return !invite.userAlreadyHasTeamRoles[team.id]
            })

            if (unclaimedTeams.length > 0) {
                return next()
            }

            app.events.trigger("AcceptInvitationFlow.Complete")

        }, [next, state.invitation]),

        previewMerge: useCallback(async () => {

            // start preview merge request
            const teamId = state.invitation?.teams![0].id
            const prototeamId = state.prototeamOptions.linkedTeam?.id

            if (!teamId || !prototeamId) {
                return;
            }

            const response = await invitationCodeService.loadMergePreview(teamId, prototeamId)

            // save error
            if (response.status === 'error') {
                setState(state => ({ ...state, mergedPreview: undefined, error: response.errors.map((e: any) => e.message).join() }));
                return
            }

            // set mergedPreview and continue
            setState(state => ({ ...state, rawMergedData: response.data["preview-invitation"] }));
            next();

        }, [next, setState, invitationCodeService.loadMergePreview, state.invitation, state.prototeamOptions]),

        acceptInvitationCode: useCallback(async () => {

            // accept invitation
            const schedulerInvite = state.invitation?.invitation?.roles?.some((role: any) => role.title === "scheduler")
            const code = state.invitation?.invitation?.code
            const teamId = state.invitation?.teams![0]?.id || 0
            const strategy = state.prototeamOptions.strategy
            const prototeamId = state.prototeamOptions.linkedTeam?.id || ""

            if (!code || (!teamId && !schedulerInvite) || !strategy) {
                return;
            }

            if (strategy === "link" && !prototeamId) {
                return;
            }

            const response = await invitationCodeService.acceptInvitation(code, strategy, teamId, prototeamId)
            if (response.status === 'error') {
                console.error(response.errors);
                setState(state => ({ ...state, error: response.errors.map((e: any) => e.message).join() }));
                return;
            };

            app.events.trigger("AcceptInvitationFlow.ExistingUser.Complete")
            app.events.trigger("AcceptInvitationFlow.Complete")
            await user.Expire();
            app.setAcceptedInvite(true)
            loginFlow.reset()

            // return user back to where they came from
            navigate("/games");

        }, [navigate, invitationCodeService.acceptInvitation, state.invitation, state.prototeamOptions, user.Expire, loginFlow.reset, app.setAcceptedInvite, app.events.trigger]),

        registerNewAccount: useCallback(async (formdata: SignupFormState) => {

            //check it valid
            for (const field of Object.values(formdata)) {
                if (!field.value.match(field.test)) {
                    return;
                }
            }

            const data: RegistrationData = {
                firstName: formdata.firstName.value,
                lastName: formdata.lastName.value,
                email: formdata.email.value,
                password: formdata.password.value,
            }

            setState(state => ({ ...state, loading: true }))

            // create account in gamesheet
            const response = await invitationCodeService.createAccount(state.invitationCode, data.firstName, data.lastName, data.email, data.password)
            if (response.status === 'error') {
                const isAlreadyTaken = response?.errMsg.some((error: any) => error.title === "is already taken");

                if(isAlreadyTaken){
                    setState(state => ({ ...state, loading: false, error: `Email: ${data.email} is already in use` }))
                    return;
                } else {
                    setState(state => ({ ...state, loading: false}))
                }
            
                return;
            };

            // create firebaser account
            const user = await createUserWithEmailAndPassword(auth, data.email, data.password).then(async (userCredential) => {
                return userCredential.user
            })
            user && await updateProfile(user, { displayName: `${data.firstName} ${data.lastName}` })

            // update firebase user id
            const fuidResponse = await invitationCodeService.setFirebaseUserId(data.email, user.uid)
            if (fuidResponse.status === 'error') {
                console.error(fuidResponse);
                setState(state => ({ ...state, loading: false }))
                return;
            };

            app.events.trigger("AcceptInvitationFlow.NewUser.Complete")
            app.events.trigger("AcceptInvitationFlow.Complete")
            app.setAcceptedInvite(true)

            resetSignupForm()
            navigate("/games")
            setState(state => ({ ...state, loading: false }))

        }, [state.invitation, setState, resetSignupForm, invitationCodeService.setFirebaseUserId]),

        setStrategy: (strategy: "link" | "add" | "") => {
            setState(s => ({ ...s, strategy }))
        },

        setLinkedTeam: (team: TeamData | undefined) => {
            setState(state => ({ ...state, linkedTeam: team }))
        },

        sampleTeam,

        mergedPreview,

        strategy: state.prototeamOptions.strategy,

    }

}